import React, { JSX, useState, useEffect } from 'react'
import {ChevronLeft, ChevronRight} from "react-ikonate";

interface CarouselProps {
    children: JSX.Element[];
    autoSlide?: boolean;
    autoSlideInterval?: number;
}

export function Carousel(props: CarouselProps) {
    const [curr, setCurr] = useState(0)

    const prev = () => setCurr((curr) => (curr === 0 ? props.children.length - 1 : curr - 1))
    const next = () => setCurr((curr) => (curr === props.children.length - 1 ? 0 : curr + 1))

    useEffect(() => {
        if (!props.autoSlide) return
        const slideInterval = setInterval(next, props.autoSlideInterval)
        return () => clearInterval(slideInterval)
    }, [next, props.autoSlide, props.autoSlideInterval])


    return (
        <div className='w-full h-full overflow-hidden relative'>
            <div className='w-full h-full flex transition-transform ease-out duration-500' style={{ transform: `translateX(-${curr * 100}%)` }}>
                {props.children}
                {/*.map((child, id) => (
                    <div id={"carouselId-" + id}>
                        {child}
                    </div>
                ))}*/}
            </div>
            <div className="absolute inset-0 flex items-center justify-between p-4">
                <button onClick={prev} className='p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white'>
                    <ChevronLeft onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </button>
                <button onClick={next} className='p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white'>
                    <ChevronRight onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </button>
            </div>
            <div className='absolute bottom-4 right-0 left-0'>
                <div className='flex items-center justify-center gap-2'>
                    {props.children.map((element, index) => (
                        <div key={index} className={`transition-all w-1.5 h-1.5 bg-white rounded-full  ${curr === index ? "p-0.5" : "bg-opacity-50"}`} />
                    ))}
                </div>
            </div>
        </div>
    )
}
