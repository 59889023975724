interface ButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
    inverted?: boolean;
}

function Button(props: ButtonProps) {
    return (
        <div
            onClick={props.onClick}
            className={`flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-[45px] py-5 rounded-md border-2 w-fit cursor-pointer transition duration-300 ease-in-out border-[#d966f9] ${props.inverted ? "bg-[#d966f9] hover:bg-CPmagenta hover:border-CPmagenta" : "backdrop-blur-[35px] bg-[rgba(217,102,249,0.1)] hover:bg-[rgba(161,12,202,0.3)]"}`}
            style={{
                boxShadow: "0px 4px 4px 0 rgba(0,0,0,0.25)",
            }}
        >
            <div className="flex-grow-0 flex-shrink-0 w-[138px] h-7">
                <div className="w-[138px] h-7 my-auto -ml-[45px]">
                    <p className={`w-full absolute text-xl font-bold text-center ${props.inverted ? "text-black" : "text-[#d966f9]"}`}>
                        {props.text}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Button;
