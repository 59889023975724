import {JSX, useEffect, useState} from "react";

function Navbar({children}: { children: JSX.Element[] }) {
    const [displayBackground, setDisplayBackground] = useState(false);

    useEffect(() => {
        setDisplayBackground(window.scrollY > 0);

        const onScroll = () => {
            setDisplayBackground(window.scrollY > 0);
            console.log(window.scrollY);
        };

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {passive: true});

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className={`z-50 fixed top-0 w-full p-6 flex justify-between ${displayBackground ? "bg-gradient-to-b from-black from-50%" : ""}`}>
            <img src="/images/logo.png" alt="logo" className="w-[100px]"/>
            <nav className={`flex-grow my-auto flex w-full justify-center relative gap-[50px] transform -translate-x-[50px]`}>
                {children.map((child) => (
                    <>{child}</>
                ))}
            </nav>
        </div>
    );
}

export default Navbar;
