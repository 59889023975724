interface NavbarLinkProps {
    name: string;
    href: string;
    styles?: string;
}

function NavbarLink(props: NavbarLinkProps) {
    return (
        <a href={props.href} onClick={event => {
            if (!props.href.startsWith("#")) return;
            event.preventDefault();
            document.querySelector(props.href)?.scrollIntoView({ behavior: "smooth" });
        }} className={`relative block text-lg font-bold uppercase text-white font-subTitle w-fit flex-grow-0 flex-shrink-0 custom-underline ${props.styles}`}>
            {props.name}
        </a>
    );
}

export default NavbarLink;

