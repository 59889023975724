import Navbar from "../components/Navbar";
import NavbarLink from "../components/NavbarLink";
import Button from "../components/Button";
import {Carousel} from "../components/Carrousel";
import {useEffect} from "react";

function HomeHero() {
    return (
        <div id={"accueil"} className={"relative block h-full min-h-screen max-h-[1080px]"} style={{
            backgroundImage: "url('/images/hero.webp')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        }}>
            {/* Overlay */}
            <div className={"absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-black via-transparent via-10% z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-transparent via-transparent via-90% to-black z-0"}/>

            {/* Text */}
            <div className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5"}>
                <p className={"text-[100px] text-center uppercase text-white font-title"}>La cité des pixels</p>
                <p className={"text-[30px] text-center uppercase text-white font-title -translate-y-10"}>Cité caritative en lan</p>

                <div className={"flex justify-center gap-10"}>
                    <Button text={"En savoir plus"} onClick={() => {
                        // Scroll to about section
                        window.scrollTo({
                            top: document.getElementById("a-propos")?.offsetTop,
                            behavior: "smooth"
                        });
                    }}/>
                    <Button text={"Faire un don !"} inverted={true} onClick={() => {
                        window.open("https://streamlabscharity.com/teams/@cite-des-pixels/cite-des-pixels", "_blank");
                    }}/>
                </div>
            </div>
        </div>
    );
}

function AboutSection() {
    return (
        <div id={"a-propos"} className={"relative block min-h-screen max-h-[1080px] h-full"} style={{
            backgroundImage: "url('/images/about.webp')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        }}>
            {/* Overlay */}
            <div className={"absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-black via-transparent via-10% z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-transparent via-transparent via-90% to-black z-0"}/>

            {/* Text */}
            <div className={"w-full h-full min-h-screen max-h-[1080px] flex flex-col justify-center"}>
                <div className={"w-[calc(85%)] mx-auto h-full min-h-[calc(60vh)] my-auto grid grid-cols-5 gap-10"}>
                    <div className={"min-h-full h-full flex flex-col justify-center z-10 col-span-3"}>
                        <p className={"text-3xl uppercase text-white font-title"}>c’est quoi la cité des pixels ?</p>
                        <p className={"text-8xl uppercase text-white font-title underline"} style={{
                            textDecorationThickness: "0.03em"
                        }}>un tournoi caritatif</p>

                        <p className={"mt-5 w-1/2 mx-auto text-white font-body text-justify"}>
                            La Cité des Pixels est un tournoi Minecraft qui verra s’affronter 16 équipes de 6 joueurs en présentiel à Lyon du 22 au 27 juin 2024. Les équipes s’affronteront sur un mode de jeu “cité” en plus de devoir faire plusieurs événements In-Game et IRL tel que du PvP, du buildBattle mais surtout de la pétanque et des émissions plateaux de légendes ! La Cité des Pixels sera avant tout en événement rassemblant dans la bonne ambiances les plus grands Minecraftiens français et francophones !
                            <div className={"w-full flex justify-end mt-2"}>
                                <div className={"w-1/6 h-2 bg-CPdarkMagenta"}></div>
                            </div>
                        </p>
                    </div>
                    <div className={"min-h-full h-full flex flex-col justify-center z-10 col-span-2"}>
                        <Carousel autoSlideInterval={3000} autoSlide={true}>
                            <img src={"/images/c1.webp"} alt={"About 1"} className={"h-full object-cover rounded-2xl"}/>
                            <img src={"/images/c2.webp"} alt={"About 2"} className={"h-full object-cover rounded-2xl"}/>
                            <img src={"/images/c3.webp"} alt={"About 3"} className={"h-full object-cover rounded-2xl"}/>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CagnoteSection() {
    useEffect(() => {
        const url = "https://streamlabscharity.com/api/v1/teams/@cite-des-pixels/cite-des-pixels";
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const counter = document.getElementById("counter");
                if (counter) {
                    const amount = data.amount_raised.toString();
                    const euros = amount.slice(0, -2) || "0";
                    const cents = amount.slice(-2) || "00";

                    counter.innerText = `${euros},${cents} €`;
                }
            });
    }, []);

    return (
        <div id={"cagnotte"} className={"relative block min-h-dvh max-h-[1080px] h-full"}>
            {/* Overlay */}
            <div className={"absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-black via-transparent via-10% z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-transparent via-transparent via-90% to-black z-0"}/>

            {/* Text */}
            <div className={"w-full h-full min-h-screen max-h-[1080px] flex flex-col justify-center"}>
                <div className={"w-[calc(85%)] mx-auto h-full min-h-[calc(60vh)] my-auto grid grid-cols-5 gap-10"}>
                    <div className={"min-h-full h-full flex flex-col justify-center z-10 col-span-3"}>
                        <img alt={"Image Unicef"} src={"images/unicef.webp"} className={"h-full object-cover"}/>
                    </div>

                    <div className={"min-h-full h-full flex flex-col justify-center z-10 col-span-2"}>
                        <p className={"text-6xl uppercase text-white font-title"}>Notre cagnotte</p>

                        <p className={"text-8xl p-3 uppercase font-title text-CPpink"}><span id={"counter"}>0,00 €</span></p>

                        <p className={"mt-5 w-full text-white font-body text-justify"}>
                            L’UNICEF – pour United Nations International Children’s Emergency Fund, soit Fonds des Nations unies pour l’enfance – est une agence des Nations unies, créée en 1946, dont le siège est à New York, aux États-Unis. Elle est chargée, dans le monde entier, de défendre les droits des enfants, de répondre à leurs besoins essentiels et de favoriser leur plein épanouissement.
                            <div className={"w-full flex justify-end mt-2"}>
                                <div className={"w-1/6 h-2 bg-CPdarkMagenta"}></div>
                            </div>
                        </p>

                        <div className={"mt-20 flex gap-5"}>
                            <Button text={"Faire un don !"} inverted={true} onClick={() => {
                                window.open("https://streamlabscharity.com/teams/@cite-des-pixels/cite-des-pixels", "_blank");
                            }}/>
                            <Button text={"Boutique"} inverted={true} onClick={() => {
                                window.open("https://citedespixels.ninestore.fr", "_blank");
                            }}/>
                        </div>
                        <div className={"mt-10 mb-20 flex gap-10"}>
                            <Button text={"Découvrir l'Unicef"} onClick={() => {
                                window.open("https://unicef.fr", "_blank");
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function StreamingSection() {
    return (
        <div id={"le-stream"} className={"relative block h-full min-h-screen max-h-[1080px]"} style={{
            backgroundImage: "url('/images/streaming.webp')",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        }}>
            {/* Overlay */}
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-black via-transparent via-10% z-0"}/>
            <div className={"absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gradient-to-b from-transparent via-transparent via-90% to-black z-0"}/>

            {/* Text */}
            <div className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-96"}>
                <div className={"transform -translate-y-32"}>
                    <p className={"text-[100px] text-center uppercase text-white font-title"}>Rejoins nous en stream !</p>
                    <p className={"text-[30px] text-center uppercase text-white font-title -translate-y-10"}>Ils sont en live</p>
                </div>

                <div className={"flex justify-center"}>
                    <Button text={"Aller sur le live !"} onClick={() => {
                        window.open("https://twitch.com/bichard", "_blank");
                    }}/>
                </div>
            </div>
        </div>
    );
}

function RegisterSection() {
    return (
        <div id={"s-inscrire"} className={"relative block min-h-screen max-h-[1080px] h-full"}>
            {/* Text */}
            <div className={"w-full h-full min-h-screen max-h-[1080px] flex flex-col justify-center"}>
                <div className={"w-[calc(85%)] mx-auto h-full min-h-[calc(60vh)] my-auto grid grid-cols-5 gap-40"}>
                    <div className={"min-h-full h-full flex flex-col justify-center z-10 col-span-2"}>
                        <img alt={"Cochon minecraft"} src={"images/pig.webp"} className={"h-full object-contain"}/>
                    </div>

                    <div className={"min-h-full h-full flex flex-col justify-center z-10 col-span-3"}>
                        <p className={"text-3xl mb-5 uppercase text-white font-title"}>Tu veux participer ?</p>
                        <p className={"text-8xl uppercase text-white font-title"}>Inscris toi !</p>

                        <p className={"mt-5 w-2/3 text-white font-body text-justify"}>
                            Les inscriptions pour les joueurs de la Cité des Pixels sont ouvertes à toutes et à tous du 1er Mai au 31 Mai. Inscrivez-vous seul ou en équipe de 6 et tentez d’être sélectionné pour participer à notre évènement !
                            <div className={"w-full flex justify-end mt-2"}>
                                <div className={"w-1/6 h-2 bg-CPdarkMagenta"}></div>
                            </div>
                        </p>

                        <div className={"mt-20 flex gap-10"}>
                            <Button text={"S'inscrire"} inverted={true} onClick={() => {
                                window.open("https://inscription.citedespixels.fr", "_blank");
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FooterSection() {
    return (
        <div className={"relative block ml-20 mr-20 mb-20"}>
            <div className={"flex justify-end m-3"}>
                <div className={"flex gap-5"}>
                    <a href={"https://twitter.com/citedespixels"} target={"_blank"} rel={"noreferrer"}>
                        <img alt={"Twitter"} src={"/images/x.webp"} className={"w-5 h-5"}/>
                    </a>
                    <a href={"https://twitch.com/bichard"} target={"_blank"} rel={"noreferrer"}>
                        <img alt={"Twitch"} src={"/images/twitch.webp"} className={"w-5 h-5"}/>
                    </a>
                    <a href={"https://instagram.com/citedespixels"} target={"_blank"} rel={"noreferrer"}>
                        <img alt={"Instagram"} src={"/images/instagram.webp"} className={"w-5 h-5"}/>
                    </a>
                </div>
            </div>
            <hr className={"opacity-40"}/>
            <div className={"flex justify-between m-3"}>
                <p className={"text-white text-sm"}>© 2024 Bde Quest Education - Tous droits réservés | Site par Azn9 (contact@azn9.dev)</p>
                <div className={"flex gap-5"}>
                    <NavbarLink name={"Politique de confidentialité et mentions légales"} href={"https://citedespixels.fr/mentions-legales-cite-des-pixels.pdf"} styles={"text-sm"} />
                </div>
            </div>
        </div>
    );
}

function Home() {
    return (
        <>
            <Navbar>
                <NavbarLink name={"Accueil"} href={"#accueil"}/>
                <NavbarLink name={"À propos"} href={"#a-propos"}/>
                <NavbarLink name={"Cagnotte"} href={"#cagnotte"}/>
                <NavbarLink name={"Le stream"} href={"#le-stream"}/>
                <NavbarLink name={"Boutique"} href={"https://citedespixels.ninestore.fr"}/>
                {/* <NavbarLink name={"S'inscrire"} href={"#s-inscrire"}/> */}
            </Navbar>
            <HomeHero/>
            <AboutSection/>
            <CagnoteSection/>
            <StreamingSection/>
            {/* <RegisterSection/> */}
            <FooterSection/>
        </>
    );
}

export default Home;
